@import "../scss/include";

@mixin card-size($grid-gutter-width) {
  flex: 1 0 calc(20% - #{$grid-gutter-width});
  margin: $grid-gutter-width / 2;
}

:global(.card-deck) :global(.card).card {
  @include card-size(4px);
  background-color: #efdcb9;
  position: relative;

  @include media-breakpoint-up(md) {
    @include card-size(30px);
  }

  &.highlight {
    opacity: 1;
  }

  @include media-breakpoint-down(sm) {
    height: 2.5em;
    justify-content: center;
    :global(.card-body) {
      padding: 0 .1em !important;
      flex: 0 0 auto;
    }
  }
}

@include media-breakpoint-down(sm) {
  .word {
    font-size: .8em;
    font-weight: bold;
    background-color: transparent !important;
    margin: 0;
  }
}

.button {
  cursor: pointer;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;

  :global(.card-deck.pending) & {
    cursor: not-allowed;
  }
}

.cardUpper {
  display: none;
  @include media-breakpoint-up(md) {
    display: block;
  }

  .visual {
    width: 20%;
    padding-top: 20%;
    margin-left: 1em;
    background: #d1c7b5;
    float: right;
  }

  .secondTitle {
    width: calc(80% - 1em);
    float: right;
    border-top: 2px solid #d1c7b5;
    color: rgba(0, 0, 0, 0.5);
    transform: rotateZ(180deg);
  }

  &:after {
    content: '';
    clear: both;
    display: block;
  }
}

.card .cover {
  transform-origin: center;
  border-radius: $border-radius;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  transition: transform .4s, box-shadow .4s;

  $card-images: (
          0 0,
          0 1,
          1 0,
          1 1,
          -1 0,
          "null" 0,
          "null" 1
  );

  @each $type, $visual in $card-images {
    &:global(.type-#{$type}.visual-#{$visual}) {
      background-image: url("../assets/images/card-#{$type}-#{$visual}.jpeg");
    }
  }

  border: 1px solid rgba(0, 0, 0, 0.1);
  cursor: grab;

  @include media-breakpoint-up(md) {
    &:hover {
      transform: scale(1.02) !important;
      box-shadow: 0 0 10px 0px rgba(0, 0, 0, 0.2);
    }
  }

  &.out {
    z-index: 110 !important;
    cursor: grabbing;
    transform: rotate(-60deg) translate(25%, 110%) scale(1.05) !important;
    box-shadow: 10px 10px 10px 5px rgba(0, 0, 0, 0.2);
    &.left {
      transform: rotate(60deg) translate(-25%, 110%) scale(1.05) !important;
    }
  }
}

