@import "./scss/bootstrap";

html {
  min-height: 100%;
  height:100%;
}

body {
  direction: rtl;
  text-align: right;
  height: 100%;
}
#root {
  height: 100%;
}
