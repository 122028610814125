@import "../scss/include";

.map {
  width: 100%;
  max-width: 250px;
  margin: 0 auto;
  font-size: 0;
  background: #745e45;
  box-sizing: border-box;
  padding: 1rem;
  position: relative;

  .tiles {
    padding: .7rem;
    background: $black;
    border-radius: $border-radius;
  }

  .indicator {
    position: absolute;
    background: red;
    $padding: calc(100% - 1rem);
    &.top {
      bottom: $padding;
    }

    &.bottom {
      top: $padding;
    }

    &.left {
      right: $padding;
    }

    &.right {
      left: $padding;
    }

    &.y {
      height: .4rem;
      width: 2rem;
      left: 50%;
      transform: translate(-50%, 0);
    }

    &.x {
      height: 2rem;
      width: .4rem;
      top: 50%;
      transform: translate(0, -50%);
    }
  }

  .tile {
    border-radius: $border-radius;
    box-sizing: border-box;
    display: inline-block;
    margin: .1rem;
    position: relative;
    cursor: pointer;

    &:global(.bg-card-null) {
      &:after {
        display: none;
      }
    }

    &:after {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      display: block;
      content: '';
    }


    &:global(.bg-card--1) {
      &:after {
        content: '✖';
        color: $white !important;
        font-size: 1.5rem;
        width: auto;
        border: none;
        font-weight: 100;
        @include media-breakpoint-up(md) {
          font-size: .7rem;
        }
        @include media-breakpoint-up(lg) {
          font-size: 1rem;
        }
        @include media-breakpoint-up(xl) {
          font-size: 1.7rem;
        }
      }
    }

    &:global(:not(.bg-card--1)) {
      &:after {
        opacity: .2;
        border: 2px solid $white;
        width: 60%;
        padding-top: 60%;
        height: 0;
        line-height: 0;
        font-size: 0;
        box-sizing: content-box;
        border-radius: 50%;
      }
    }
  }
}
