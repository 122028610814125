$black:    #000000;
$blue:    #007bff;
$red:     #dc3545;
$orange:  #fd7e14;
$yellow:  #ffc107;
$green:   #28a745;
$pink:    #e83e8c;

$card-spacer-x: .5em;

$font-family-sans-serif: 'Heebo', 'Open Sans',arial, sans-serif;

$theme-colors: (
    "card-null": #c0b5aa,
    "card--1": $black,
    "card-0": #3675a4,
    "card-1": #cc3b35,
    "card-2": $green,
    "card-3": $pink,
    "card-4": $yellow
);
