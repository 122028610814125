@import "../scss/include";
.waitMessage {
  position: absolute;
  z-index: 2;
}
.deck {
  display: flex;
  flex-wrap: wrap;
  max-width: 1200px;
  margin: 0 auto;

  @include media-breakpoint-down(md) {
    margin-top: 1em;
  }
  :global(.card) {
    transition: opacity .5s;
  }
}

.hasHighlight {
  :global(.card) {
    opacity: .4;
  }

  .highlight:global(.card) {
    opacity: 1;
  }
}
